import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { OpenAPI } from "./api";
import env from "./env";
import lang from "./lang";
import LoginPage from "./pages/LoginPage";
import theme from "./theme";

OpenAPI.BASE = env.REACT_APP_API_BASE_URL;

const queryClient = new QueryClient();

function App() {
  const locale = navigator.language.split(/[-_]/)[0];

  return (
    <IntlProvider defaultLocale="en" locale={locale} messages={lang[locale]}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route element={<LoginPage />} path="/" />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
