/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchExceptionRequestDto = {
  categoryId?: number;
  manufacturerId?: number;
  manufacturerProductId?: number;
  searchExceptionType?: SearchExceptionRequestDto.searchExceptionType;
  supplierId?: number;
};
export namespace SearchExceptionRequestDto {
  export enum searchExceptionType {
    EXCLUSION = 'exclusion',
    INCLUSION = 'inclusion',
  }
}

