/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditCardAuthorizationOrderRequestDto } from './CreditCardAuthorizationOrderRequestDto';
import type { CustomOrderHeaderRequestDto } from './CustomOrderHeaderRequestDto';
export type QuotePostRequestDto = {
  additionalHeaders?: Array<CustomOrderHeaderRequestDto>;
  attention?: string;
  billToId?: number;
  confirmationEmails?: Array<string>;
  costCenter?: string;
  creditCardAuth?: CreditCardAuthorizationOrderRequestDto;
  epoRequest?: boolean;
  instructions?: string;
  internalNotes?: string;
  internalOnly?: boolean;
  notes?: string;
  paymentType?: string;
  po?: string;
  precomputedFreight?: boolean;
  project?: string;
  reference?: string;
  sendConfirmation?: boolean;
  shipLevel?: number;
  shipMethod?: string;
  shipToId: number;
  shippingFee?: number;
  sourceId: number;
  sourceType: QuotePostRequestDto.sourceType;
  warehouseShipToId: number;
};
export namespace QuotePostRequestDto {
  export enum sourceType {
    CART_QUOTE = 'CART|QUOTE',
  }
}

