import { useMutation } from "@tanstack/react-query";
import { AuthResourceService } from "../../../api";
import { CLIENT_ID, CLIENT_SECRET } from "../../../constants";

export const useAuthUrl = () => {
  return useMutation({
    mutationFn: (email: string) => {
      return AuthResourceService.getLoginUrlByEmailUsingGet(
        CLIENT_ID,
        CLIENT_SECRET,
        email,
      );
    },
  });
};
