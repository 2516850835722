/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GridViewReportScheduleDto = {
  accountCode?: string;
  canManageApplicationUser?: boolean;
  dateOfMonth?: number;
  dayPeriod?: number;
  daysOfWeek?: GridViewReportScheduleDto.daysOfWeek;
  deliveryMethod?: GridViewReportScheduleDto.deliveryMethod;
  email?: string;
  emailFileNameDatestamp?: boolean;
  format?: GridViewReportScheduleDto.format;
  frequency?: GridViewReportScheduleDto.frequency;
  ftpFilename?: string;
  ftpFilepath?: string;
  ftpPassword?: string;
  ftpPort?: number;
  ftpServer?: string;
  ftpUsername?: string;
  gridViewId?: number;
  id?: number;
  monthOfYear?: GridViewReportScheduleDto.monthOfYear;
  monthPeriod?: number;
  sendBlank?: boolean;
  timeslot?: GridViewReportScheduleDto.timeslot;
  weekOfMonth?: GridViewReportScheduleDto.weekOfMonth;
  weekPeriod?: number;
  yearPeriod?: number;
};
export namespace GridViewReportScheduleDto {
  export enum daysOfWeek {
    ALL = 'all',
    FRIDAY = 'friday',
    MONDAY = 'monday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
    THURSDAY = 'thursday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    WEEKDAYS = 'weekdays',
  }
  export enum deliveryMethod {
    EMAIL = 'email',
    FTP = 'ftp',
    SFTP = 'sftp',
  }
  export enum format {
    CSV = 'csv',
    PDF = 'pdf',
    TSV = 'tsv',
    TXT = 'txt',
    XLSX = 'xlsx',
  }
  export enum frequency {
    DAILY = 'daily',
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    YEARLY = 'yearly',
  }
  export enum monthOfYear {
    JULY = 'July',
    ALL = 'all',
    APRIL = 'april',
    AUGUST = 'august',
    DECEMBER = 'december',
    FEBRUARY = 'february',
    JANUARY = 'january',
    JUNE = 'june',
    MARCH = 'march',
    MAY = 'may',
    NOVEMBER = 'november',
    OCTOBER = 'october',
    SEPTEMBER = 'september',
  }
  export enum timeslot {
    EVENING = 'evening',
    MORNING = 'morning',
  }
  export enum weekOfMonth {
    _1ST = '1st',
    _2ND = '2nd',
    _3RD = '3rd',
    _4TH = '4th',
    ALL = 'all',
    LAST = 'last',
  }
}

