/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FavoriteProductResponseDto = {
  /**
   * Response Only
   */
  _labels?: Record<string, string>;
  /**
   * Response Only
   */
  _links?: Record<string, Array<string>>;
  createdAt: string;
  description: string;
  largeImageUrl?: string;
  mfgName?: string;
  mfgPart?: string;
  price: number;
  primaryImageUrl?: string;
  productId: number;
  productType?: FavoriteProductResponseDto.productType;
  sort: number;
  standard?: boolean;
  title: string;
  width400ImageUrl?: string;
};
export namespace FavoriteProductResponseDto {
  export enum productType {
    BUNDLE = 'BUNDLE',
    PRODUCT = 'PRODUCT',
  }
}

