/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponseDto } from '../models/AuthResponseDto';
import type { LoginUrlResponseDto } from '../models/LoginUrlResponseDto';
import type { PunchoutSetupPostRequestDto } from '../models/PunchoutSetupPostRequestDto';
import type { PunchoutSetupResponseDto } from '../models/PunchoutSetupResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthResourceService {
  /**
   * Get a user token for authorized interfaces from a valid a authenticatedLinkHash and clientIpAddress
   * Get a user token for authorized interfaces from a valid a authenticatedLinkHash and clientIpAddress
   * @param authenticatedLinkHash authenticatedLinkHash
   * @param clientId client_id
   * @param clientSecret client_secret
   * @param clientIpAddress clientIpAddress
   * @returns AuthResponseDto Success
   * @throws ApiError
   */
  public static getAuthenticateLinkTokenUsingGet(
    authenticatedLinkHash: string,
    clientId: string,
    clientSecret: string,
    clientIpAddress: string,
  ): CancelablePromise<AuthResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/auth/authenticated_link',
      query: {
        'authenticatedLinkHash': authenticatedLinkHash,
        'client_id': clientId,
        'client_secret': clientSecret,
        'clientIpAddress': clientIpAddress,
      },
      errors: {
        400: `Bad request`,
        401: `You are not authorized to access this resource`,
        403: `You do not have the required permissions to access this resource`,
        404: `Not Found`,
        500: `Internal Error retrieving authenticated link token`,
      },
    });
  }
  /**
   * Generate access token
   * Generate access token
   * @param clientId client_id
   * @param clientSecret client_secret
   * @param grantType grant_type
   * @param password password
   * @param username username
   * @returns AuthResponseDto Success
   * @throws ApiError
   */
  public static generateAccessTokenUsingGet(
    clientId?: string,
    clientSecret?: string,
    grantType?: string,
    password?: string,
    username?: string,
  ): CancelablePromise<AuthResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/auth/oauth2',
      query: {
        'client_id': clientId,
        'client_secret': clientSecret,
        'grant_type': grantType,
        'password': password,
        'username': username,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Access token not found.`,
        500: `Internal Error retrieving access token`,
      },
    });
  }
  /**
   * Generate access token
   * Generate access token
   * @param clientId client_id
   * @param clientSecret client_secret
   * @param grantType grant_type
   * @param password password
   * @param username username
   * @returns AuthResponseDto Success
   * @returns any Created
   * @throws ApiError
   */
  public static generateAccessTokenUsingPost(
    clientId?: string,
    clientSecret?: string,
    grantType?: string,
    password?: string,
    username?: string,
  ): CancelablePromise<AuthResponseDto | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/auth/oauth2',
      query: {
        'client_id': clientId,
        'client_secret': clientSecret,
        'grant_type': grantType,
        'password': password,
        'username': username,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Access token not found.`,
        500: `Internal Error retrieving access token`,
      },
    });
  }
  /**
   * Punchout login
   * Punchout login
   * @param buyerCookie buyerCookie
   * @param clientId client_id
   * @param clientSecret client_secret
   * @returns AuthResponseDto Success
   * @throws ApiError
   */
  public static punchoutLoginUsingGet(
    buyerCookie?: string,
    clientId?: string,
    clientSecret?: string,
  ): CancelablePromise<AuthResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/auth/punchout',
      query: {
        'buyerCookie': buyerCookie,
        'client_id': clientId,
        'client_secret': clientSecret,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Punchout login not found.`,
        500: `Internal Error retrieving Punchout login`,
      },
    });
  }
  /**
   * POST Punchout Setup
   * POST Punchout Setup
   * @param clientId client_id
   * @param clientSecret client_secret
   * @param requestDto requestDto
   * @returns PunchoutSetupResponseDto Success
   * @throws ApiError
   */
  public static createPunchoutSetupUsingPost(
    clientId: string,
    clientSecret: string,
    requestDto: PunchoutSetupPostRequestDto,
  ): CancelablePromise<PunchoutSetupResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/auth/punchoutSetup',
      query: {
        'client_id': clientId,
        'client_secret': clientSecret,
      },
      body: requestDto,
      errors: {
        400: `Bad Request`,
        401: `The user is not authorized to access this request`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get user login url
   * Get user login url
   * @param clientId client_id
   * @param clientSecret client_secret
   * @param email email
   * @returns LoginUrlResponseDto Success
   * @throws ApiError
   */
  public static getLoginUrlByEmailUsingGet(
    clientId: string,
    clientSecret: string,
    email: string,
  ): CancelablePromise<LoginUrlResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/auth/url',
      query: {
        'client_id': clientId,
        'client_secret': clientSecret,
        'email': email,
      },
      errors: {
        400: `Bad request`,
        401: `You are not authorized to access this resource`,
        403: `You do not have the required permissions to access this resource`,
        404: `Not Found`,
        500: `Internal Error retrieving login url`,
      },
    });
  }
  /**
   * Get a customer user token
   * Get a customer user token
   * @param userId userId
   * @param clientId client_id
   * @param clientSecret client_secret
   * @returns AuthResponseDto Success
   * @throws ApiError
   */
  public static assumeCustomerRoleUsingGet(
    userId: number,
    clientId?: string,
    clientSecret?: string,
  ): CancelablePromise<AuthResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/auth/users/{userId}',
      path: {
        'userId': userId,
      },
      query: {
        'client_id': clientId,
        'client_secret': clientSecret,
      },
      errors: {
        401: `You are not authorized to access this customer token.`,
        403: `Forbidden`,
        404: `User with {userId} not found.`,
        500: `Internal Error retrieving customer token`,
      },
    });
  }
}
