/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type XmlMapRequestDto = {
  apiElement: XmlMapRequestDto.apiElement;
  regex?: string;
  xmlSource: string;
};
export namespace XmlMapRequestDto {
  export enum apiElement {
    ADDTIONAL_HEADER_D = 'addtionalHeader.\d',
    ATTENTION = 'attention',
    BILL_TO_FULL_ADDRESS_ADDRESS = 'billToFullAddress.address',
    BILL_TO_FULL_ADDRESS_ADDRESS2 = 'billToFullAddress.address2',
    BILL_TO_FULL_ADDRESS_ATTENTION = 'billToFullAddress.attention',
    BILL_TO_FULL_ADDRESS_CITY = 'billToFullAddress.city',
    BILL_TO_FULL_ADDRESS_COMPANY_NAME = 'billToFullAddress.companyName',
    BILL_TO_FULL_ADDRESS_STATE = 'billToFullAddress.state',
    BILL_TO_FULL_ADDRESS_ZIP = 'billToFullAddress.zip',
    CONFIRMATION_EMAILS = 'confirmationEmails',
    COST_CENTER = 'costCenter',
    EXTERNAL_ID = 'externalId',
    EXTERNAL_ORDER_ID = 'externalOrderId',
    EXTERNAL_REQUEST_DATE = 'externalRequestDate',
    INSTRUCTIONS = 'instructions',
    LINES_CUSTOM1 = 'lines.custom1',
    LINES_CUSTOM2 = 'lines.custom2',
    LINES_CUSTOM3 = 'lines.custom3',
    LINES_CUSTOM4 = 'lines.custom4',
    LINES_DESCRIPTION = 'lines.description',
    LINES_MFG_NAME = 'lines.mfg_name',
    LINES_MFG_PART = 'lines.mfg_part',
    LINES_ORIGINAL_LINE_NUMBER = 'lines.originalLineNumber',
    LINES_QUANTITY = 'lines.quantity',
    LINES_REQUESTED_DELIVERY_DATE = 'lines.requestedDeliveryDate',
    LINES_SUPPLIER_CODE = 'lines.supplierCode',
    LINES_SUPPLIER_PART = 'lines.supplierPart',
    LINES_UNIT_PRICE = 'lines.unitPrice',
    PO = 'po',
    PROJECT = 'project',
    REFERENCE = 'reference',
    SHIP_TO_FULL_ADDRESS_ADDRESS = 'shipToFullAddress.address',
    SHIP_TO_FULL_ADDRESS_ADDRESS2 = 'shipToFullAddress.address2',
    SHIP_TO_FULL_ADDRESS_CITY = 'shipToFullAddress.city',
    SHIP_TO_FULL_ADDRESS_COMPANY_NAME = 'shipToFullAddress.companyName',
    SHIP_TO_FULL_ADDRESS_EXTERNAL_SHIP_TO_ID = 'shipToFullAddress.externalShipToId',
    SHIP_TO_FULL_ADDRESS_STATE = 'shipToFullAddress.state',
    SHIP_TO_FULL_ADDRESS_ZIP = 'shipToFullAddress.zip',
    USER_AGENT = 'userAgent',
  }
}

