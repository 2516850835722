import { createTheme } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    white: string;
    blue: ColorPartial;
    red: ColorPartial;
  }

  interface Palette {
    white: string;
    blue: ColorPartial;
    red: ColorPartial;
  }
}

const theme = createTheme({
  palette: {
    blue: {
      800: "#0082C9",
      900: "#0076BD",
    },
    primary: {
      main: "#39474F",
    },
    red: {
      500: "#FCE8E8",
      700: "#E11717",
    },
    white: "#FFFFFF",
  },
  typography: {
    fontFamily: `'Encode Sans', sans-serif`,
  },
});

export default theme;
