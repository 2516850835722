import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Stack,
  Typography,
  styled,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { ReactComponent as ConnectionLogoSvg } from "../../assets/svgs/connection-logo.svg";
import { ReactComponent as ErrorIcon } from "../../assets/svgs/error-icon.svg";
import { ReactComponent as InlineErrorIcon } from "../../assets/svgs/inline-error-icon.svg";
import { useAuthUrl } from "../../hooks/api/mutations";
import messages from "./messages";

const StyledPage = styled(Box)(({ theme }) => ({
  background: theme.palette.grey["100"],
  minHeight: "100vh",
  position: "relative",
}));
const StyledLoginBox = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  background: theme.palette.white,
  borderRadius: "8px",
  boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.16)",
  left: "50%",
  padding: "32px 24px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(56.25),
}));
const StyledLogoBox = styled(Box)(() => ({
  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  marginBottom: "16px",
}));
const StyledErrorAlert = styled(Alert)(({ theme }) => ({
  "& .MuiAlert-icon": {
    margin: "0 8px 0 0",
    padding: "0",
  },
  "& .MuiAlert-message": {
    color: theme.palette.red["700"],
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "28px",
    padding: "0",
  },
  background: theme.palette.red["500"],
  border: `1px solid ${theme.palette.red["700"]}`,
  borderRadius: "8px",
  display: "flex",
  padding: "8px 12px",
}));
const StyledEmailInput = styled(TextField)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    color: theme.palette.red["700"],
    marginLeft: 0,
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    fontSize: theme.spacing(1.875),
    fontWeight: 500,
    lineHeight: theme.spacing(2.5),
    padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  },
  "& .MuiInputBase-root.Mui-error fieldset": {
    borderColor: theme.palette.red["700"],
  },
  "& .MuiInputBase-root.Mui-focused fieldset": {
    borderWidth: 1,
  },
  "& fieldset": {
    border: `1px solid ${theme.palette.grey["400"]}`,
  },
  marginBottom: "4px",
}));
const StyledSubmitBtn = styled(LoadingButton)(({ theme }) => ({
  "&:hover": {
    background: theme.palette.blue[800],
  },
  background: theme.palette.blue[900],
  fontSize: "16px",
  fontWeight: 700,
  marginTop: "12px",
  textTransform: "none",
}));
const StyledGuideTypo = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "16px",
  marginBottom: "24px",
  marginTop: "16px",
  textAlign: "left",
}));
const StyledInlineErrorIcon = styled(InlineErrorIcon)(({ theme }) => ({
  marginRight: "4px",
}));
type LoginFormValues = {
  email: string;
};

const LoginPage = () => {
  const { formatMessage } = useIntl();
  const schema = yup
    .object({
      email: yup
        .string()
        .email(formatMessage(messages.errorInvalidEmail))
        .required(formatMessage(messages.errorInvalidEmail)),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: yupResolver<LoginFormValues>(schema),
  });
  const { mutate: getAuthUrl, isPending, isError } = useAuthUrl();
  const onSubmit = (formValues: LoginFormValues) => {
    getAuthUrl(formValues.email, {
      onSuccess: (data) => {
        if (data.loginUrl) {
          window.location.href = data.loginUrl;
        }
      },
    });
  };

  return (
    <StyledPage>
      <form noValidate onSubmit={handleSubmit(onSubmit)} >
        <StyledLoginBox>
          <StyledLogoBox>
            <ConnectionLogoSvg />
          </StyledLogoBox>
          <Box sx={{ width: "100%" }}>
            {isError && (
              <StyledErrorAlert
                icon={<ErrorIcon />}
                severity="error"
                variant="outlined"
              >
                {formatMessage(messages.errorSignInFail)}
              </StyledErrorAlert>
            )}
          </Box>
          <StyledGuideTypo>
            {formatMessage(messages.signInTitle)}
          </StyledGuideTypo>
          <StyledEmailInput
            autoFocus
            error={!!errors.email}
            fullWidth
            placeholder={formatMessage(messages.placeholderEmail)}
            type="email"
            variant="outlined"
            {...register("email")}
          />
          {errors.email && (
            <Box>
              <FormHelperText error>
                <StyledInlineErrorIcon />
                {errors.email.message}
              </FormHelperText>
            </Box>
          )}
          <StyledSubmitBtn
            fullWidth
            loading={isPending}
            type="submit"
            variant="contained"
          >
            {formatMessage(messages.buttonNext)}
          </StyledSubmitBtn>
        </StyledLoginBox>
      </form>
    </StyledPage>
  );
};

export default LoginPage;
