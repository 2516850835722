declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_API_BASE_URL: string;
};

const env: EnvType = { ...process.env, ...window.env };

export default env;
