/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EulDefaultsFieldDto = {
  name?: string;
  type?: EulDefaultsFieldDto.type;
};
export namespace EulDefaultsFieldDto {
  export enum type {
    COUNTRY = 'country',
    DATE = 'date',
    EMAIL = 'email',
    PHONE = 'phone',
    TEXT = 'text',
  }
}

