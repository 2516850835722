import { defineMessages } from "react-intl";

const messages = defineMessages({
  buttonNext: {
    defaultMessage: "Next",
    id: "LoginPage.buttonNext",
  },
  errorInvalidEmail: {
    defaultMessage: "Please enter a valid email address.",
    id: "LoginPage.errorInvalidEmail",
  },
  errorSignInFail: {
    defaultMessage: "Unable to sign in. Please try again.",
    id: "LoginPage.errorSignInFail",
  },
  placeholderEmail: {
    defaultMessage: "Email",
    id: "LoginPage.placeholderEmail",
  },
  signInTitle: {
    defaultMessage: "Sign In",
    id: "LoginPage.signInTitle",
  },
});

export default messages;
