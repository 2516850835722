import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";

const lang: Record<string, Record<string, string>> = {
  en,
  es,
  fr,
};

export default lang;
