/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RecentlyViewedResponseDto = {
  /**
   * Response Only
   */
  _labels?: Record<string, string>;
  /**
   * Response Only
   */
  _links?: Record<string, Array<string>>;
  buildLinks?: Record<string, Array<string>>;
  description?: string;
  largeImageUrl?: string;
  manufacturerName?: string;
  manufacturerPart?: string;
  price?: number;
  primaryImageUrl?: string;
  resourceId?: number;
  standard?: boolean;
  title?: string;
  type: RecentlyViewedResponseDto.type;
  width400ImageUrl?: string;
};
export namespace RecentlyViewedResponseDto {
  export enum type {
    BUNDLE = 'BUNDLE',
    PRODUCT = 'PRODUCT',
  }
}

