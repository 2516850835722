/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ImageRequestDto = {
  image: string;
  imageAngleId: number;
  imageSizeId: number;
  main: boolean;
  relationObjectId: number;
  type?: ImageRequestDto.type;
  url: string;
};
export namespace ImageRequestDto {
  export enum type {
    ACCOUNT = 'ACCOUNT',
    PRODUCT = 'PRODUCT',
    STANDARD = 'STANDARD',
  }
}

